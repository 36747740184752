<template>
  <div id="login-register-root">
    <!-- 登录 -->
    <template v-if="type == 1">
      <div class="login">
        <div class="item-img">
          <img src="../assets/images/dialog.jpg" alt />
        </div>
        <div class="item-content">
          <h5>劳务从业人员</h5>
          <h1>加油站</h1>
          <!--<h4>账户登录</h4>-->
          <div class="login-foot">
            <span>还没有讲堂账户? 去</span>
            <a href="javascript:void(0)" @click="changeType(2)">注册</a>
          </div>
          <form action @submit.prevent="loginSubmit">
            <p>
              <span>
                <svg class="icon myIconStyle" aria-hidden="true">
                  <use xlink:href="#icon-ren"></use>
                </svg>
              </span>
              <input
                type="text"
                placeholder="手机号/邮箱/用户名"
                v-model="utel"
                autocomplete="on"
              />
            </p>
            <p>
              <span>
                <svg class="icon myIconStyle" aria-hidden="true">
                  <use xlink:href="#icon-suo"></use>
                </svg>
              </span>
              <input
                type="password"
                placeholder="请输入密码"
                v-model="upwd"
                autocomplete="off"
              />
            </p>
            <p id="login">
              <input type="submit" value="登录" />
            </p>
          </form>
        </div>
      </div>
    </template>
    <!-- 注册 -->
    <template v-if="type == 2">
      <div class="register login">
        <div class="item-img">
          <img src="../assets/images/dialog.jpg" alt />
        </div>
        <div class="item-content">
          <h5>劳务从业人员</h5>
          <h1>加油站</h1>
          <!--<h4>账户注册</h4>-->
          <div class="login-foot">
            <span>已有讲堂账户? 去</span>
            <a href="javascript:void(0)" @click="changeType(1)">登录</a>
          </div>
          <form action @submit.prevent="registerSubmit">
            <p>
              <span>用户名</span>
              <input
                type="text"
                placeholder="请输入你的用户名"
                v-model="uname"
              />
            </p>
            <p>
              <span>手机号</span>
              <input
                type="text"
                placeholder="可用于登录和找回密码"
                v-model="utel"
              />
            </p>
            <p>
              <span>密码</span>
              <input
                type="password"
                placeholder="请设置你的登录密码"
                v-model="upwd"
              />
            </p>
            <!-- <p class="code">
            <span>验证码</span>
            <input type="text" placeholder="请输入验证码" v-model="code">
            <span>获取验证码</span>
            </p>-->
            <p id="login">
              <input type="submit" value="注册" />
            </p>
          </form>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import Qs from "qs";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      // 登录1 & 注册2
      type: 1,
      utel: "",
      upwd: "",
      uname: "",
      code: "",
      routerTo: "",
      routerFrom: ""
    };
  },
  computed: {
    ...mapGetters(["getToken", "getLastPath", "getWxOpenId"])
  },
  mounted() {
    // console.log('----111-----lastpath------>', this.getLastPath)
    // alert(this.getLastPath)
    // console.log(121)
    // console.log('----222-----wxopenid------>', this.getWxOpenId)
    // alert(this.getWxOpenId)
  },
  methods: {
    // 判断是否为微信浏览器
    isWeixinBrowser() {
      let ua = navigator.userAgent.toLowerCase();
      console.log('-----------ua-----', ua)
      return /micromessenger/.test(ua) ? true : false;
    },
    // 登录or注册
    changeType(type) {
      this.type = type;
    },
    // 登录提交
    loginSubmit() {
      let _this = this
      if (this.utel && this.upwd) {
        // 验证手机号
        if (!/^1[3456789]\d{9}$/.test(this.utel)) {
          // alert("请输入正确的手机号");
          this.$notify({
            title: "警告",
            message: "请输入正确的手机号",
            type: "warning"
          });
          return;
        }
        let loginString = {
          mobile: this.utel,
          password: this.upwd
        };
        if (_this.isWeixinBrowser() && _this.getWxOpenId) {
          loginString.wx_openid = _this.getWxOpenId
        } else {
          delete loginString.wx_openid
        }
        this.$store.dispatch("login", loginString).then(res => {
          if (res.code === 0) {
            //登录成功
            _this.$store.commit('setLoginToken', res.data)
            let path =
              !_this.getLastPath || _this.getLastPath === "/login" || _this.getLastPath === "/wxLogin"
                ? "/"
                : _this.getLastPath;
            _this.$router.push(path);
            // let wxSetLastPath = localStorage.getItem("setLastPath");

            // _this.$router.push(_this.getLastPath)
            // if (wxSetLastPath) path = wxSetLastPath; //如果上个页面是微信登录，则跳转到首页
            // this.$router.push(path, wxSetLastPath);
            // localStorage.setItem("setLastPath", "");
          } else {
            this.$notify({
              title: "警告",
              message: res.message,
              type: "warning"
            });
          }
        });
      } else {
        this.$notify({
          title: "警告",
          message: "请输入正确的信息",
          type: "warning"
        });
      }
    },
    // 注册提交
    registerSubmit() {
      let _this = this
      if (this.utel && this.upwd && this.uname) {
        // 验证手机号
        if (!/^1[3456789]\d{9}$/.test(this.utel)) {
          this.$notify({
            title: "警告",
            message: "请输入正确的手机号",
            type: "warning"
          });
          return;
        }
        // 验证密码
        if (!/^\w{6,}$/.test(this.upwd)) {
          this.$notify({
            title: "警告",
            message: "密码长度大于6位",
            type: "warning"
          });
          return;
        }
        let registerString = Qs.stringify({
          nick_name: this.uname,
          mobile: this.utel,
          password: this.upwd
        });
        if (_this.isWeixinBrowser() && _this.getWxOpenId) {
          registerString.wx_openid = _this.getWxOpenId
        } else {
          delete registerString.wx_openid
        }
        this.$store.dispatch("register", registerString).then(res => {
          if (res.code === 0) {
            _this.$store.commit('setLoginToken', res.data)
            let path =
              !_this.getLastPath || _this.getLastPath === "/login" || _this.getLastPath === "/wxLogin"
                ? "/"
                : _this.getLastPath;
            _this.$router.push(path);
            // location.reload(true)
            //this.$router.push({ name: 'UserHome' })
            // let wxSetLastPath = localStorage.getItem("setLastPath");
            // let path =
            //   !this.getLastPath || this.getLastPath === "/login"
            //     ? "/"
            //     : this.getLastPath;
            // if (wxSetLastPath) path = wxSetLastPath; //如果上个页面是微信登录，则跳转到首页
            // localStorage.setItem("setLastPath", "");
            // this.$router.push(path);
          } else if (res.code === 10003) {
            this.$notify({
              title: "警告",
              message: res.message,
              type: "warning"
            });
          }
        });
      } else {
        this.$notify({
          title: "警告",
          message: "请输入正确的信息",
          type: "warning"
        });
      }
    }
  }
};
</script>
<style scoped lang="less">
#login-register-root {
  width: 100%;
  .login {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .item-img {
      width: 50%;
      text-align: center;
    }
    .item-content {
      width: 50%;
      padding-top: 70px;
      text-align: center;
      h5 {
        margin: 0;
        font-size: 1rem;
        font-weight: normal;
        letter-spacing: 2px;
      }
      h1 {
        color: #dab020;
        letter-spacing: 1px;
        margin: 0.5rem 0 3rem 0;
        letter-spacing: 5px;
      }
      h4 {
        color: #8fd4fd;
        margin: 0;
        /*margin-top: 20px;*/
        letter-spacing: 1px;
        /*margin-bottom: 10px;*/
        display: inline-block;
      }
      a {
        color: #8fd4fd;
      }
      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          width: 60%;
          margin-top: 40px;
          border: 1px solid #ccc;
          border-radius: 10px;
          display: flex;
          span {
            width: 25%;
            text-align: center;
            padding-top: 5px;
            .icon {
              width: 20px;
              height: 20px;
              vertical-align: -webkit-baseline-middle;
            }
          }
          input {
            height: 40px;
            padding-left: 5px;
            border: none;
          }
        }
        #login {
          background: #fdc82a;
          border-color: #fdc82a;
          input {
            text-align: center;
            width: 100%;
            background: none;
            color: #fff;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
      .login-foot {
        text-align: right;
        margin-top: 10px;
        margin-right: 115px;
        cursor: pointer;
        span {
          margin-left: 10px;
          /*color: #8fd4fd;*/
          font-size: 14px;
        }
      }
    }
  }
  .register {
    .item-content {
      p {
        border: none !important;
        input {
          border: none;
          border: 1px solid #ccc !important;
          padding-left: 20px !important;
          height: 40px;
          border-radius: 10px;
          background: none;
          width: 85%;
          margin-left: 10px;
        }
        span {
          line-height: 40px;
          padding-top: 0 !important;
        }
      }
      #login {
        input {
          border: none !important;
        }
      }
    }
  }
}

// 适应移动端
@media screen and (min-width: 769px) and (max-width: 1160px) {
  #login-register-root {
    .login {
      width: auto;
      .item-img {
        img {
          width: 100%;
        }
      }
      .item-content {
        form {
          p {
            width: 68%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 500px) {
  #login-register-root {
    .login {
      width: auto;
      .item-img {
        display: flex;
        align-items: flex-end;
        img {
          width: 100%;
        }
      }
      .item-content {
        padding: 0;
        form {
          p {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  #login-register-root {
    .login {
      width: auto;
      .item-img {
        display: none;
      }
      .item-content {
        width: 100%;
        padding-top: 10px;
        h2 {
          margin: 0;
        }
        form {
          p {
            width: 85%;
            margin: 10px 0;
          }
        }
        .login-foot {
          margin-right: 45px;
        }
      }
    }
  }
}
</style>
